import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';

import { AppComponent } from './app.component';
import { API_BASE_URL } from './app.definitions';
import { routes } from './app.routes';
import { CoreModule } from './core/core.module';
import { SettingsModule } from './core/settings/settings.module';
import { CustomTranslatorModule } from './shared/i18n/custom-translate.module';
import { SharedModule } from './shared/shared.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(routes, {
      enableTracing: false,
      scrollPositionRestoration: 'top',
    }),
    CoreModule,
    CustomTranslatorModule,
    SharedModule,
    SettingsModule,
  ],
  providers: [
    {
      provide: API_BASE_URL,
      useValue: environment.apiBaseUrl,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
