import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { SortByPropertyPipe } from './pipes/sort-by-property.pipe';
import { SortBySizePipe } from './pipes/sort-by-size.pipe';

@NgModule({
  imports: [CommonModule],
  declarations: [SortByPropertyPipe, SortBySizePipe],
  exports: [SortByPropertyPipe, SortBySizePipe],
})
export class SharedModule {}
