import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, of } from 'rxjs';
import { switchMap, withLatestFrom } from 'rxjs/operators';
import * as AsyncStateActions from '../actions/async-state.actions';
import { AsyncAction } from '../models/async-action.model';
import { AsyncState } from '../reducers/async-state.reducers';
import { makeGetAsyncInitialAction } from '../selectors/async-state.selectors';

@Injectable()
export class AsyncStateEffects {
  handleAsyncAction$ = createEffect(
    () =>
      this.actions$.pipe(
        switchMap((action: Action) => {
          if (!(action as AsyncAction).asyncData) {
            return EMPTY;
          }

          const asyncAction = action as AsyncAction;

          if (!asyncAction.asyncData.asyncState) {
            return EMPTY;
          }

          switch (asyncAction.asyncData.asyncState) {
            case 'start':
              return of(
                AsyncStateActions.setAsyncStateStart({
                  key: asyncAction.asyncData.asyncKey,
                  initialAction: asyncAction,
                })
              );
            case 'success':
              return of(
                AsyncStateActions.setAsyncStateSuccess({ key: asyncAction.asyncData.asyncKey })
              );
            case 'fail':
              return of(
                AsyncStateActions.setAsyncStateFail({ asyncErrorData: asyncAction.asyncData })
              );
            default:
              return EMPTY;
          }
        })
      ),
    { dispatch: true }
  );

  retryOriginalAction$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(AsyncStateActions.setAsyncStateRetry),
        withLatestFrom(this.store),
        switchMap(([action, state]: [Action, AsyncState]) => {
          if (!(action as AsyncAction).asyncData.asyncKey) {
            return EMPTY;
          }
          const asyncAction = action as AsyncAction;

          const initialAction = makeGetAsyncInitialAction(asyncAction.asyncData.asyncKey)(state);

          if (!initialAction) {
            return EMPTY;
          }

          return of(initialAction);
        })
      ),
    { dispatch: true }
  );

  constructor(private actions$: Actions, private store: Store<AsyncState>) {}
}
