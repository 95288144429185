import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { CustomTranslatorModule } from 'app/shared/i18n/custom-translate.module';

import { AsyncStateOverlayComponent } from './components/async-state-overlay/async-state-overlay.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { ASYNC_STATE_KEY } from './definitions/async-state.definitions';
import { AsyncStateEffects } from './effects/async-state.effects';
import { reducer } from './reducers/async-state.reducers';

@NgModule({
  imports: [CommonModule, CustomTranslatorModule],
  declarations: [AsyncStateOverlayComponent, SpinnerComponent],
  exports: [AsyncStateOverlayComponent, SpinnerComponent],
})
export class AsyncStateOverlayModule {}

@NgModule()
export class AsyncStateModule {
  static forRoot(): ModuleWithProviders<AsyncStateRootModule> {
    return {
      // eslint:disable-next-line: no-use-before-declare
      ngModule: AsyncStateRootModule,
    };
  }
}

@NgModule({
  imports: [
    StoreModule.forFeature(ASYNC_STATE_KEY, reducer),
    EffectsModule.forFeature([AsyncStateEffects]),
    CustomTranslatorModule,
  ],
})
export class AsyncStateRootModule {}
