import { ModuleWithProviders, NgModule } from '@angular/core';
import { ToastrModule } from 'ngx-toastr';

import { ToastService } from './services/toast.service';

@NgModule({
  imports: [
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
      preventDuplicates: true,
    }),
  ],
})
export class ToastModule {
  static forRoot(): ModuleWithProviders<RootToastModule> {
    return {
      // eslint:disable-next-line:no-use-before-declare
      ngModule: RootToastModule,
      providers: [ToastService],
    };
  }
}

@NgModule({
  imports: [ToastModule],
})
export class RootToastModule {}
