import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import * as SettingsActions from '../actions/settings.actions';
import { DarkModeService } from '../services/darkmode.service';

@Injectable()
export class SettingsEffects {
  scrollToIntroPageTop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(SettingsActions.setDarkMode),
        tap((settings) => this.DarkModeService.setProperties(settings.darkMode))
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private DarkModeService: DarkModeService) {}
}
