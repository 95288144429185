import { Pipe, PipeTransform } from '@angular/core';
import { ItemSize } from '../models/shop/shop-item.model';

@Pipe({
  name: 'sortBySize',
})
export class SortBySizePipe implements PipeTransform {
  public itemSizeOrder = Object.values(ItemSize);

  transform(itemSizes: ItemSize[]) {
    return [...itemSizes].sort(
      (a, b) => this.itemSizeOrder.indexOf(a) - this.itemSizeOrder.indexOf(b)
    );
  }
}
