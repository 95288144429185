import { animate, style, transition, trigger } from '@angular/animations';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ObservableComponent } from 'app/shared/components/observable/observable.component';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { distinctUntilChanged, filter, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { filterNullish } from 'src/app/core/async-state/utils/null-filter';

import * as AsyncStateActions from '../../actions/async-state.actions';
import { AsyncAction } from '../../models/async-action.model';
import { ErrorConfig } from '../../models/error-config.model';
import { AsyncErrorState, AsyncState } from '../../reducers/async-state.reducers';
import {
  getPendingAsyncActionsInitialActions,
  makeGetAsyncAreFailed,
  makeGetAsyncArePending,
  makeGetAsyncAreRetryable,
  makeGetAsyncErrorStateForKeys,
} from '../../selectors/async-state.selectors';

const inactiveStyle = style({
  opacity: 0,
});
const inactiveStyleChild = style({
  opacity: 0,
  transform: 'scale(0.7)',
});
const timing = '.3s ease';

@Component({
  selector: 'app-async-state-overlay',
  templateUrl: './async-state-overlay.component.html',
  styleUrls: ['./async-state-overlay.component.scss'],
  animations: [
    trigger('flyInOut', [
      transition('void => *', [inactiveStyle, animate(timing)]),
      transition('* => void', [animate(timing, inactiveStyle)]),
    ]),
    trigger('flyInOutChild', [
      transition('void => *', [inactiveStyleChild, animate(timing)]),
      transition('* => void', [animate(timing, inactiveStyleChild)]),
    ]),
  ],
})
export class AsyncStateOverlayComponent extends ObservableComponent implements OnInit {
  @ViewChild('dismissButton') dismissButton: ElementRef;

  private processKeys$: BehaviorSubject<string[]> = new BehaviorSubject<string[]>([]);
  private isComponentBasedErrorHandling$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(
    false
  );

  loading$ = this.processKeys$.pipe(
    distinctUntilChanged(),
    filter((keys: string[]) => Boolean(keys) && keys.length > 0),
    switchMap((keys: string[]) => this.store.select(makeGetAsyncArePending(keys)))
  );

  failed$ = combineLatest([
    this.processKeys$,
    this.isComponentBasedErrorHandling$,
    this.loading$,
  ]).pipe(
    distinctUntilChanged(),
    filter(
      ([keys, _, loading]: [string[], boolean, boolean]) =>
        Boolean(keys) && keys.length > 0 && !loading
    ),
    switchMap(([keys, isComponentBasedErrorHandling, _]: [string[], boolean, boolean]) => {
      if (isComponentBasedErrorHandling) {
        return of(false);
      }

      return this.store.pipe(select(makeGetAsyncAreFailed(keys)));
    })
  );

  errorConfig$ = this.processKeys$.pipe(
    distinctUntilChanged(),
    filter((keys: string[]) => Boolean(keys) && keys.length > 0),
    switchMap((keys: string[]) => this.store.pipe(select(makeGetAsyncErrorStateForKeys(keys)))),
    map(
      (errorStates: AsyncErrorState[] | undefined) =>
        errorStates && new ErrorConfig(errorStates[0], this.translate)
    )
  );

  errorTitle$ = this.errorConfig$.pipe(
    filterNullish(),
    map((errorConfig) => errorConfig.title)
  );

  errorMessage$ = this.errorConfig$.pipe(
    filterNullish(),
    map((errorConfig) => this.getFlatErrorMessage(errorConfig))
  );

  retryable$ = this.processKeys$.pipe(
    distinctUntilChanged(),
    filter((keys: string[]) => Boolean(keys) && keys.length > 0),
    switchMap((keys: string[]) => this.store.pipe(select(makeGetAsyncAreRetryable(keys)))),
    switchMap((hasRetryAction: boolean) => {
      if (!hasRetryAction) {
        return of(false);
      }

      // might be retryable. to decide, we need the error code
      return this.errorConfig$.pipe(
        map((errorConfig: ErrorConfig | undefined) => errorConfig && errorConfig.isRetryable)
      );
    })
  );

  private _processKeys: string[] = [];
  @Input() size = '40px';

  @Input()
  set processKeys(processKeys: string[]) {
    this.processKeys$.next(processKeys);
    this._processKeys = processKeys;
  }
  get processKeys(): string[] {
    return this._processKeys;
  }

  @Input()
  set isComponentBasedErrorHandling(value: boolean) {
    this.isComponentBasedErrorHandling$.next(value);
  }

  @Input() isAbsolutePositioned = false;

  @Output() retry: EventEmitter<{}> = new EventEmitter();
  @Output() dismiss: EventEmitter<{}> = new EventEmitter();

  constructor(private store: Store<AsyncState>, private translate: TranslateService) {
    super();
  }

  ngOnInit() {
    this.observeFailed();
  }

  observeFailed() {
    this.failed$
      .pipe(
        distinctUntilChanged(),
        tap((failed: boolean) => {
          if (failed) {
            setTimeout(() => {
              this.dismissButton?.nativeElement.focus();
            }, 0);
          }
        }),
        takeUntil(this.ngDestroy$)
      )
      .subscribe();
  }

  public onRetryClick() {
    this.store
      .pipe(select(getPendingAsyncActionsInitialActions))
      .pipe(
        take(1),
        tap((initialActions) => {
          // retry for error(s)
          this.processKeys.forEach((key: string) => {
            if (key !== '') {
              this.store.dispatch(
                AsyncStateActions.setAsyncStateRetry({
                  key: key,
                  initialAction: initialActions.find(
                    (x) => (x as AsyncAction).asyncData.asyncKey === key
                  )!,
                })
              );
            }

            if (this.retry.observers && this.retry.observers.length > 0) {
              this.retry.emit();
            }
          });
        })
      )
      .subscribe();
  }

  public onDismissClick() {
    this.processKeys$
      .pipe(
        distinctUntilChanged(),
        filter((keys: string[]) => Boolean(keys) && keys.length > 0),
        switchMap((keys: string[]) => this.store.pipe(select(makeGetAsyncErrorStateForKeys(keys)))),
        take(1)
      )
      .subscribe((_errorState: AsyncErrorState[] | undefined) => {
        if (this.dismiss.observers && this.dismiss.observers.length > 0) {
          this.dismiss.emit();
        }

        // reset error(s)
        this.processKeys.forEach((key: string) => {
          if (key !== '') {
            this.store.dispatch(AsyncStateActions.setAsyncStateReset({ key: key }));
          }
        });
      });
  }

  private getFlatErrorMessage(errorConfig: ErrorConfig): string {
    const message = errorConfig.messages.reduce((reducedMessage, currentMessage, currentIndex) => {
      let newMessage = reducedMessage;
      if (currentIndex > 0) {
        newMessage += '\n';
      }
      newMessage += currentMessage;
      return newMessage;
    }, '');
    return message;
  }
}
