import { TranslateService } from '@ngx-translate/core';
import { AsyncErrorState } from '../reducers/async-state.reducers';
import { isCallWithCodeRetryable } from '../utils/retryablilty-utils';

export class ErrorConfig {
  title = '';
  messages: string[] = [];
  isRetryable = false;

  constructor(errorState: AsyncErrorState, private translate: TranslateService) {
    if (
      (errorState && errorState.httpStatus && errorState.httpStatus >= 0) ||
      (errorState && errorState.validationErrors)
    ) {
      this.isRetryable = isCallWithCodeRetryable(errorState.httpStatus ?? 10);
      this.title = this.createErrorTitle(errorState.httpStatus ?? 10, errorState);
      this.messages = this.createErrorMessages(errorState);
    } else {
      this.isRetryable = false;
      this.title =
        errorState.errorTitle || this.translate.instant('async_state.default_error_title');
      this.messages = [
        errorState.errorText || this.translate.instant('async_state.default_error_message'),
      ];
    }
  }

  private createErrorMessages(errorState: AsyncErrorState): string[] {
    if (errorState.validationErrors && errorState.validationErrors.length > 0) {
      const messages: string[] = [];
      errorState.validationErrors.forEach((error) => {
        if (!error.messages) {
          return;
        }
        error.messages.forEach((message: string) => messages.push(message));
      });
      if (messages.length > 0) {
        return messages;
      }
    }

    if (errorState.errorText) {
      return [errorState.errorText];
    }

    switch (errorState.httpStatus) {
      case 0:
        return [this.translate.instant('async_state.error_message_0')];
      case 403:
        return [this.translate.instant('async_state.error_message_403')];
      case 401:
        return [this.translate.instant('async_state.error_message_401')];
      case 404:
        return [this.translate.instant('async_state.error_message_404')];
      case 400:
        return [this.translate.instant('async_state.error_message_400')];
      case 429:
        return [this.translate.instant('async_state.error_message_429')];
      case 504:
        return [this.translate.instant('async_state.error_message_504')];
      default:
        return [this.translate.instant('async_state.default_error_message')];
    }
  }

  private createErrorTitle(code: number, errorState: AsyncErrorState): string {
    if (errorState.errorTitle) {
      return errorState.errorTitle;
    }

    switch (code) {
      case 0:
        return this.translate.instant('async_state.error_title_0');
      case 403:
      case 401:
        return this.translate.instant('async_state.error_title_401');
      case 404:
        return this.translate.instant('async_state.error_title_404');
      case 400:
        return this.translate.instant('async_state.error_title_400');
      case 504:
        return this.translate.instant('async_state.error_title_504');
      default:
        return this.translate.instant('async_state.default_error_title');
    }
  }
}
