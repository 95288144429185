import { createAction, props } from '@ngrx/store';

export enum IntropageActionTypes {
  SetIntroPageShown = '[IntroPage] Set shown',
  ScrollToIntroPageTop = '[IntroPage] Scroll Top',
}

export const scrollToIntroPageTop = createAction(IntropageActionTypes.ScrollToIntroPageTop);

export const setIntroPageShown = createAction(
  IntropageActionTypes.SetIntroPageShown,
  props<{ introShown: boolean }>()
);
