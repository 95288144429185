import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ProductItemDetailsModalComponent } from 'app/views/shop-ui/components/product-item-details-modal/product-item-details-modal.component';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { tap } from 'rxjs/operators';

import { closeProductModal, openProductModal } from '../actions/product-modal.actions';

@Injectable()
export class ProductModalEffects {
  private openModal: BsModalRef | undefined;

  openModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openProductModal),
        tap((action) => {
          this.openModal = this.modalService.show(ProductItemDetailsModalComponent, {
            animated: false,
            backdrop: false,
            ignoreBackdropClick: true,
            initialState: {
              item: action.shopItem,
            },
          });
        })
      ),
    { dispatch: false }
  );

  closeModal$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(closeProductModal),
        tap(() => {
          this.openModal?.hide();
        })
      ),
    { dispatch: false }
  );

  constructor(private actions$: Actions, private modalService: BsModalService) {}
}
