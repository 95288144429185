import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { environment } from 'src/environments/environment';

import {
  EMAIL_STATE_KEY,
  SELECTED_SHOP_STATE_KEY,
  SHOP_STATE_KEY,
} from './definitions/shop.definitions';
import { ProductModalEffects } from './effects/product-modal.effects';
import { ShopEffects } from './effects/shop.effects';
import { cartReducer } from './reducers/cart.reducer';
import { selectedShopReducer } from './reducers/selected-shop.reducer';
import { shopReducer } from './reducers/shop.reducer';
import { CanvasService } from './services/canvas.service';
import { IntroPageScrollService } from './services/intro-page-scroll.service';
import { ShopApiMockService } from './services/mock-shop-api.service';
import { ShopApiService } from './services/shop-api.service';

@NgModule({
  imports: [
    StoreModule.forFeature(SHOP_STATE_KEY, shopReducer),
    StoreModule.forFeature(EMAIL_STATE_KEY, cartReducer),
    StoreModule.forFeature(SELECTED_SHOP_STATE_KEY, selectedShopReducer),
    EffectsModule.forFeature([ShopEffects, ProductModalEffects]),
  ],
  providers: [
    IntroPageScrollService,
    CanvasService,
    {
      provide: ShopApiService,
      useClass: environment.useMockServices ? ShopApiMockService : ShopApiService,
    },
  ],
})
export class ShopModule {}
