import { createReducer, on } from '@ngrx/store';
import { ShopItem } from 'app/shared/models/shop/shop-item.model';
import { setIntroPageShown } from '../actions/intropage.actions';
import { getAllShopItemsSuccess } from '../actions/shop.actions';

export interface ShopItemState {
  items: ShopItem[] | undefined;
  introShown: boolean;
}

export const initialState: ShopItemState = {
  items: undefined,
  introShown: true,
};

export const shopReducer = createReducer(
  initialState,
  on(getAllShopItemsSuccess, (state: ShopItemState, { items }) => ({
    ...state,
    items: items,
  })),
  on(setIntroPageShown, (state: ShopItemState, { introShown }) => ({
    ...state,
    introShown: introShown,
  }))
);
