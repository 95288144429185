import { ApiResponse } from 'app/shared/models';
import { Observable, of, pipe, throwError, UnaryFunction } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';

import { AsyncAction } from '../models/async-action.model';

export function handleErrors(
  createAction: () => AsyncAction
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
): UnaryFunction<Observable<any>, Observable<any>> {
  const errorAction = createAction();
  return pipe(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    switchMap((response: ApiResponse<any>) => {
      if (!response.success) {
        const errorResponseAction = {
          ...errorAction,
          asyncData: {
            ...errorAction.asyncData,
            validationErrors: response.validationErrors,
          },
        };

        return throwError(errorResponseAction);
      } else {
        return of(response);
      }
    }),
    catchError((error: unknown) => {
      // This was the implementation previously, an error occurs with this because asyncError
      // is readonly under certain circumstances:
      //   errorAction.asyncData.asyncError = error
      // new implementation works fine:

      const errorResponseAction = {
        ...errorAction,
        asyncData: {
          ...errorAction.asyncData,
          asyncError: error,
        },
      };

      return throwError(errorResponseAction);
    })
  );
}
