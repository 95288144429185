import { Injectable } from '@angular/core';

@Injectable()
export class DarkModeService {
  private white = '#ffffff';
  private black = '#000000';
  private bsBlack = 'rgb(0,0,0)';
  private bsWhite = 'rgb(255,255,255)';
  private bsLightGrey = 'rgb(211,211,211)';
  private bsDarkGrey = 'rgb(111,115,120)';
  private introBackgroundImgLight = 'url("../../../../assets/images/Hero_BG_1.svg")';
  private introBackgroundMobileImgLight = 'url("../../../../assets/images/lightMode.svg")';
  private introBackgroundImgDark = 'url("../../../../assets/images/Hero_BG_2.svg")';
  private introBackgroundMobildeImgDark = 'url("../../../../assets/images/darkMode.svg")';
  private exitBtnDark = 'url("../../../../assets/images/close_black.svg")';
  private exitBtnLight = 'url("../../../../assets/images/close_white.svg")';
  private arrowBlack = 'url("../../../../assets/images/arrow_black.svg")';
  private arrowWhite = 'url("../../../../assets/images/arrow_white.svg")';
  private whiteEmptyCart = 'url("../../../../assets/images/cart_empty_white.svg")';
  private blackEmptyCart = 'url("../../../../assets/images/cart_empty_black.svg")';
  private whiteFullCart = 'url("../../../../assets/images/cart_full_white.svg")';
  private blackFullCart = 'url("../../../../assets/images/cart_full_black.svg")';
  private blackHoodie = 'url("../../../../assets/images/prod_hoodie_black.png")';
  private whiteHoodie = 'url("../../../../assets/images/prod_hoodie_white.png")';

  public setProperties(darkMode: boolean) {
    if (darkMode) {
      document.documentElement.style.setProperty('--white-color', this.black);
      document.documentElement.style.setProperty('--black-color', this.white);
      document.documentElement.style.setProperty('--bs-white-rgb', this.bsBlack);
      document.documentElement.style.setProperty('--bs-black-rgb', this.bsWhite);
      document.documentElement.style.setProperty('--intro-page-bg', this.introBackgroundImgDark);
      document.documentElement.style.setProperty(
        '--intro-page-mobile-bg',
        this.introBackgroundMobildeImgDark
      );
      document.documentElement.style.setProperty('--exit-btn', this.exitBtnLight);
      document.documentElement.style.setProperty('--arrow', this.arrowWhite);
      document.documentElement.style.setProperty('--cart-empty', this.whiteEmptyCart);
      document.documentElement.style.setProperty('--cart-full', this.whiteFullCart);
      document.documentElement.style.setProperty('--test-hoodie', this.whiteHoodie);
      document.documentElement.style.setProperty('--bs-gray', this.bsDarkGrey);
    } else {
      document.documentElement.style.setProperty('--white-color', this.white);
      document.documentElement.style.setProperty('--black-color', this.black);
      document.documentElement.style.setProperty('--bs-white-rgb', this.bsWhite);
      document.documentElement.style.setProperty('--bs-black-rgb', this.bsBlack);
      document.documentElement.style.setProperty('--intro-page-bg', this.introBackgroundImgLight);
      document.documentElement.style.setProperty(
        '--intro-page-mobile-bg',
        this.introBackgroundMobileImgLight
      );
      document.documentElement.style.setProperty('--exit-btn', this.exitBtnDark);
      document.documentElement.style.setProperty('--arrow', this.arrowBlack);
      document.documentElement.style.setProperty('--cart-empty', this.blackEmptyCart);
      document.documentElement.style.setProperty('--cart-full', this.blackFullCart);
      document.documentElement.style.setProperty('--test-hoodie', this.blackHoodie);
      document.documentElement.style.setProperty('--bs-gray', this.bsLightGrey);
    }
  }
}
