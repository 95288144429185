import { createAction, props } from '@ngrx/store';
import { ShopItem } from 'app/shared/models/shop/shop-item.model';

enum ProductModalActionTypes {
  OpenProductModal = '[Product Modal] Open',
  CloseProductModal = '[Product Modal] Close',
}

export const openProductModal = createAction(
  ProductModalActionTypes.OpenProductModal,
  props<{ shopItem: ShopItem }>()
);

export const closeProductModal = createAction(ProductModalActionTypes.CloseProductModal);
