export interface ShopItem {
  id: string;
  title: string;
  sizes: ItemSize[];
  dark: Item;
  light: Item;
  gender: Gender[];
  category: Category[];
}

interface Item {
  pictureUrl: string;
}

export enum Gender {
  Male = 0,
  Female = 1,
}

export enum Category {
  Tshirt = 0,
  Hoodie = 1,
  Accessory = 2,
}

export enum ItemSize {
  ExtraSmall = 'XS',
  Small = 'S',
  Medium = 'M',
  Large = 'L',
  ExtraLarge = 'XL',
  XXL = 'XXL',
}
