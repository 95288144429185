export const API_BASE_URL = 'apiBaseUrl';
export const LIGHT_SERACOM_LOGO = 'assets/images/seracom_logo_white.svg';
export const DARK_SERACOM_LOGO = 'assets/images/seracom_logo_black.svg';
export const LIGHT_EMPTY_CART = 'assets/images/cart_empty_white.svg';
export const DARK_EMPTY_CART = 'assets/images/cart_empty_black.svg';
export const LIGHT_FULL_CART = 'assets/images/cart_full_white.svg';
export const DARK_FULL_CART = 'assets/images/cart_full_black.svg';
export const FILTER_WHITE = 'assets/images/filter_white.svg';
export const FILTER_BLACK = 'assets/images/filter_black.svg';
export const CLOSE_WHITE = 'assets/images/close_white.svg';
export const CLOSE_BLACK = 'assets/images/close_black.svg';
