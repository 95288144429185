import { props } from '@ngrx/store';
import { createAsyncAction } from 'app/core/async-state/utils/async-state-action.utils';
import { SendCodeRequest } from 'app/shared/models/shop/send-code-request.model';
import { SendEmailRequest } from 'app/shared/models/shop/send-email-request.model';
import { ShopItem } from 'app/shared/models/shop/shop-item.model';

export enum ShopItemActionTypes {
  GetAllShopItems = '[Shop] Get all shop items',
  GetAllShopItemsSuccess = '[Shop] Get all shop items success',
  GetAllShopItemsFail = '[Shop] Get all shop items failure',
  ConfirmEmail = '[Shop] Confirm Email address',
  ConfirmEmailSuccess = '[Shop] Confirm Email address success',
  ConfirmEmailFail = '[Shop] Confirm Email address failure',
  ConfirmCode = '[Shop] Confirm Code',
  ConfirmCodeSuccess = '[Shop] Confirm Code success',
  ConfirmCodeFail = '[Shop] Confirm Code failure',
  ResetCode = '[Shop] Reset Code',
  IncreaseStepCount = '[Shop] Change Step Count',
  ResetStepCount = '[Shop] Reset Step Count',
}

export const getAllShopItems = createAsyncAction(ShopItemActionTypes.GetAllShopItems, {
  asyncKey: ShopItemActionTypes.GetAllShopItems,
  asyncState: 'start',
});

export const getAllShopItemsSuccess = createAsyncAction(
  ShopItemActionTypes.GetAllShopItemsSuccess,
  {
    asyncKey: ShopItemActionTypes.GetAllShopItems,
    asyncState: 'success',
  },
  props<{ items: ShopItem[] }>()
);

export const getAllShopItemsFail = createAsyncAction(ShopItemActionTypes.GetAllShopItemsFail, {
  asyncKey: ShopItemActionTypes.GetAllShopItems,
  asyncState: 'fail',
});

export const confirmEmail = createAsyncAction(
  ShopItemActionTypes.ConfirmEmail,
  {
    asyncKey: ShopItemActionTypes.ConfirmEmail,
    asyncState: 'start',
  },
  props<{ request: SendEmailRequest }>()
);

export const confirmEmailSuccess = createAsyncAction(
  ShopItemActionTypes.ConfirmEmailSuccess,
  {
    asyncKey: ShopItemActionTypes.ConfirmEmail,
    asyncState: 'success',
  },
  props<{ orderId: string }>()
);

export const confirmEmailFail = createAsyncAction(ShopItemActionTypes.ConfirmEmailFail, {
  asyncKey: ShopItemActionTypes.ConfirmEmail,
  asyncState: 'fail',
});

export const confirmCode = createAsyncAction(
  ShopItemActionTypes.ConfirmCode,
  {
    asyncKey: ShopItemActionTypes.ConfirmCode,
    asyncState: 'start',
  },
  props<{ request: SendCodeRequest }>()
);

export const confirmCodeSuccess = createAsyncAction(
  ShopItemActionTypes.ConfirmCodeSuccess,
  {
    asyncKey: ShopItemActionTypes.ConfirmCode,
    asyncState: 'success',
  },
  props<{ success: boolean }>()
);

export const confirmCodeFail = createAsyncAction(ShopItemActionTypes.ConfirmCodeFail, {
  asyncKey: ShopItemActionTypes.ConfirmCode,
  asyncState: 'fail',
});

export const resetCode = createAsyncAction(ShopItemActionTypes.ResetCode, {
  asyncKey: ShopItemActionTypes.ResetCode,
  asyncState: 'success',
});

export const increaseStepCount = createAsyncAction(ShopItemActionTypes.IncreaseStepCount, {
  asyncKey: ShopItemActionTypes.IncreaseStepCount,
  asyncState: 'success',
});

export const resetStepCount = createAsyncAction(ShopItemActionTypes.ResetStepCount, {
  asyncKey: ShopItemActionTypes.ResetStepCount,
  asyncState: 'success',
});
