import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { setIntroPageShown } from 'app/core/shop/actions/intropage.actions';
import { Subject } from 'rxjs';

@Injectable()
export class IntroPageScrollService {
  public scroll$ = new Subject();

  constructor(private store: Store) {}

  public scrollToPageTop() {
    this.store.dispatch(setIntroPageShown({ introShown: true }));
    this.scroll$.next();
  }
}
