import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AsyncAction } from 'app/core/async-state/models/async-action.model';
import { handleErrors } from 'app/core/async-state/operators/handle-errors';
import { IntroPageScrollService } from 'app/core/shop/services/intro-page-scroll.service';
import { ApiResponse } from 'app/shared/models';
import { SendEmailRequest } from 'app/shared/models/shop/send-email-request.model';
import { ShopItem } from 'app/shared/models/shop/shop-item.model';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { openOffCanvas } from '../actions/canvas.action';
import { scrollToIntroPageTop } from '../actions/intropage.actions';
import * as SelectedShopActions from '../actions/selected-shop.actions';
import * as ShopActions from '../actions/shop.actions';
import { CanvasService } from '../services/canvas.service';
import { ShopApiService } from '../services/shop-api.service';

@Injectable()
export class ShopEffects {
  allShopItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.getAllShopItems),
      switchMap(() =>
        this.apiService.getAllShopItems().pipe(
          handleErrors(() => ShopActions.getAllShopItemsFail()),
          map((items: ApiResponse<ShopItem[]>) =>
            ShopActions.getAllShopItemsSuccess({ items: items.data })
          ),
          catchError((errorAction: AsyncAction) => of(errorAction))
        )
      )
    )
  );

  scrollToIntroPageTop$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(scrollToIntroPageTop),
        tap(() => this.scrollService.scrollToPageTop())
      ),
    { dispatch: false }
  );

  openOffCanvas$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(openOffCanvas),
        tap(() => this.canvasService.openOffCanvas())
      ),
    { dispatch: false }
  );

  confirmEmail$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.confirmEmail),
      map((action) => action.request),
      switchMap((request: SendEmailRequest) =>
        this.apiService.sendCodeRequest(request).pipe(
          handleErrors(() => ShopActions.confirmEmailFail()),
          map((response: ApiResponse<string>) =>
            ShopActions.confirmEmailSuccess({
              orderId: response.data,
            })
          ),
          catchError((errorAction: AsyncAction) => of(errorAction))
        )
      )
    )
  );

  confirmCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.confirmCode),
      switchMap((action) =>
        this.apiService.sendCodeConfirm(action.request).pipe(
          handleErrors(() => ShopActions.confirmCodeFail()),
          map((response: ApiResponse<boolean>) =>
            ShopActions.confirmCodeSuccess({ success: response.data })
          ),
          catchError((errorAction: AsyncAction) => of(errorAction))
        )
      )
    )
  );

  confirmEmailSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.confirmEmailSuccess),
      map(() => ShopActions.increaseStepCount())
    )
  );

  removeItemsOnCodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.confirmCodeSuccess),
      filter((response) => response.success),
      map(() => SelectedShopActions.removeAllSelectedShopItems())
    )
  );

  increaseStepOnCodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ShopActions.confirmCodeSuccess),
      filter((response) => response.success),
      map(() => ShopActions.increaseStepCount())
    )
  );

  constructor(
    private actions$: Actions,
    private apiService: ShopApiService,
    private scrollService: IntroPageScrollService,
    private canvasService: CanvasService
  ) {}
}
