import { createReducer, on } from '@ngrx/store';

import {
  increaseStepCount as increaseStepCount,
  confirmCodeSuccess,
  resetCode,
  confirmEmailSuccess,
  resetStepCount,
} from '../actions/shop.actions';

export interface CartState {
  orderId: string;
  step: number;
  codeSuccess: boolean | undefined;
}

export const initialState: CartState = {
  orderId: '',
  step: 0,
  codeSuccess: undefined,
};

export const cartReducer = createReducer(
  initialState,
  on(confirmEmailSuccess, (state: CartState, { orderId }) => ({
    ...state,
    orderId,
  })),
  on(confirmCodeSuccess, (state: CartState, { success }) => ({
    ...state,
    codeSuccess: success,
  })),
  on(increaseStepCount, (state: CartState) => ({
    ...state,
    step: state.step + 1,
  })),
  on(resetStepCount, (state: CartState) => ({
    ...state,
    step: 0,
  })),
  on(resetCode, () => initialState)
);
