import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import { storeFreeze } from 'ngrx-store-freeze';

import { environment } from 'src/environments/environment';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface BaseAppState {}

export const reducers: ActionReducerMap<BaseAppState> = {};

export const metaReducers: Array<MetaReducer<BaseAppState>> = !environment.production
  ? [storeFreeze]
  : [];
