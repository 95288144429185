import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { API_BASE_URL } from 'app/app.definitions';
import { ApiResponse } from 'app/shared/models';
import { SendCodeRequest } from 'app/shared/models/shop/send-code-request.model';
import { SendEmailRequest } from 'app/shared/models/shop/send-email-request.model';
import { ShopItem } from 'app/shared/models/shop/shop-item.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { v4 } from 'uuid';

@Injectable()
export class ShopApiService {
  constructor(@Inject(API_BASE_URL) private apiBaseUrl: string, private httpClient: HttpClient) {}

  getAllShopItems(): Observable<ApiResponse<ShopItem[]>> {
    return this.httpClient
      .get<ShopItem[]>(`${window.location.origin}/assets/shop-items/items.json`)
      .pipe(
        map(
          (items) =>
            <ApiResponse<ShopItem[]>>{
              success: true,
              data: items.map((item) => ({ ...item, id: v4() })),
              validationErrors: [],
            }
        )
      );
  }

  sendCodeRequest(request: SendEmailRequest): Observable<ApiResponse<string>> {
    const url = `${this.apiBaseUrl}/shop/send`;
    return this.httpClient.post<ApiResponse<string>>(url, request);
  }

  sendCodeConfirm(request: SendCodeRequest): Observable<ApiResponse<boolean>> {
    const url = `${this.apiBaseUrl}/shop/verify`;
    return this.httpClient.post<ApiResponse<boolean>>(url, request);
  }
}
