import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { settingsReducer } from '../settings/reducers/settings.reducer';
import { SETTINGS_STATE_KEY } from './definitions/settings.definitions';
import { SettingsEffects } from './effects/settings.effects';
import { DarkModeService } from './services/darkmode.service';

@NgModule({
  imports: [
    StoreModule.forFeature(SETTINGS_STATE_KEY, settingsReducer),
    EffectsModule.forFeature([SettingsEffects]),
  ],
  providers: [DarkModeService],
})
export class SettingsModule {}
