import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiResponse } from 'app/shared/models';
import { SendCodeRequest } from 'app/shared/models/shop/send-code-request.model';
import { SendEmailRequest } from 'app/shared/models/shop/send-email-request.model';
import { ShopItem } from 'app/shared/models/shop/shop-item.model';
import { Observable, of } from 'rxjs';
import { delay, map } from 'rxjs/operators';
import { v4 } from 'uuid';

@Injectable()
export class ShopApiMockService {
  constructor(private httpClient: HttpClient) {}
  getAllShopItems(): Observable<ApiResponse<ShopItem[]>> {
    return this.httpClient
      .get<ShopItem[]>(`${window.location.origin}/assets/shop-items/items.json`)
      .pipe(
        map(
          (items) =>
            <ApiResponse<ShopItem[]>>{
              success: true,
              data: items.map((item) => ({ ...item, id: v4() })),
              validationErrors: [],
            }
        )
      );
  }

  sendCodeRequest(_emailInfos: SendEmailRequest): Observable<ApiResponse<string>> {
    const result: ApiResponse<string> = {
      success: true,
      data: '1',
      validationErrors: [],
    };

    return of(result).pipe(delay(1000));
  }

  sendCodeConfirm(request: SendCodeRequest): Observable<ApiResponse<boolean>> {
    const result: ApiResponse<boolean> = {
      success: true,
      data: '111111' == request.code,
      validationErrors: [],
    };

    return of(result).pipe(delay(1000));
  }
}
