import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByProperty',
})
export class SortByPropertyPipe implements PipeTransform {
  transform(objectArray: object[], propertyName: string) {
    return [...objectArray].sort((a, b) => a[propertyName] - b[propertyName]);
  }
}
