import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable()
export class CanvasService {
  public open$ = new Subject();

  public openOffCanvas() {
    this.open$.next();
  }
}
