import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { FILTER_BLACK, FILTER_WHITE } from 'app/app.definitions';
import { setDarkMode } from 'app/core/settings/actions/settings.actions';
import { getIsDarkMode } from 'app/core/settings/selectors/settings.selectors';
import { openOffCanvas } from 'app/core/shop/actions/canvas.action';
import { closeProductModal } from 'app/core/shop/actions/product-modal.actions';
import { addSelectedShopItem } from 'app/core/shop/actions/selected-shop.actions';
import { getSelectedShopItems } from 'app/core/shop/selectors/selected-shop.selectors';
import { BaseAppState } from 'app/core/store/reducers';
import { SelectedShopItem } from 'app/shared/models/shop/selected-shop-item.model';
import { ShopItem } from 'app/shared/models/shop/shop-item.model';
import { take, tap } from 'rxjs/operators';
import { v4 } from 'uuid';

@Component({
  selector: 'app-product-item-details-modal',
  templateUrl: './product-item-details-modal.component.html',
  styleUrls: ['./product-item-details-modal.component.scss'],
})
export class ProductItemDetailsModalComponent implements OnInit {
  public isDarkMode$ = this.store.pipe(select(getIsDarkMode));
  public selectedShopItems$ = this.store.pipe(select(getSelectedShopItems));

  public item: ShopItem;
  public sizeGroup = new FormGroup({
    size: new FormControl('', [Validators.required]),
  });
  public isDarkMode: boolean;
  public closedModal: boolean;
  get size() {
    return this.sizeGroup.get('size');
  }
  public filterOpened: boolean;
  public readonly filterWhite = FILTER_WHITE;
  public readonly filterBlack = FILTER_BLACK;

  constructor(private store: Store<BaseAppState>) {}

  ngOnInit() {
    this.closedModal = false;
    this.isDarkMode$
      .pipe(
        take(1),
        tap((darkMode: boolean) => (this.isDarkMode = !darkMode))
      )
      .subscribe();
  }

  public closeModal() {
    this.closedModal = true;
    setTimeout(() => this.store.dispatch(closeProductModal()), 400);
  }

  public addSelectedShopItem() {
    this.sizeGroup.markAsTouched();
    if (!this.size?.valid) {
      return;
    }

    const selectedShopItem: SelectedShopItem = {
      id: v4(),
      size: this.size?.value,
      shopItemId: this.item.id,
      isDark: this.isDarkMode,
    };
    this.store.dispatch(
      addSelectedShopItem({
        selectedShopItem: selectedShopItem,
      })
    );
    this.store.dispatch(openOffCanvas());
  }

  public toggleDarkMode(darkMode: boolean): void {
    this.store.dispatch(
      setDarkMode({
        darkMode: darkMode,
      })
    );
    this.isDarkMode = !darkMode;
  }

  public setSelectedSize(option: string) {
    this.size?.setValue(option);
  }
}
