import { createReducer, on } from '@ngrx/store';
import { SelectedShopItem } from 'app/shared/models/shop/selected-shop-item.model';
import {
  addSelectedShopItem,
  removeAllSelectedShopItems,
  removeSelectedShopItem,
} from '../actions/selected-shop.actions';

export interface SelectedShopItemState {
  items: SelectedShopItem[];
}

export const initialState: SelectedShopItemState = {
  items: [],
};

export const selectedShopReducer = createReducer(
  initialState,
  on(addSelectedShopItem, (state: SelectedShopItemState, { selectedShopItem }) => ({
    ...state,
    items: [...state.items, selectedShopItem],
  })),
  on(removeSelectedShopItem, (state: SelectedShopItemState, { selectedItemId }) => ({
    ...state,
    items: handleRemoveItems(state.items, selectedItemId),
  })),
  on(removeAllSelectedShopItems, (state: SelectedShopItemState) => ({
    ...state,
    items: [],
  }))
);

function handleRemoveItems(items: SelectedShopItem[], newItemId: string) {
  const foundItemIndex = items.findIndex((item) => item.id == newItemId);
  if (foundItemIndex === -1) {
    return items;
  }

  const newItems = [...items];
  newItems.splice(foundItemIndex, 1);

  return newItems;
}
