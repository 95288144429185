import { createAction, props } from '@ngrx/store';
import { SelectedShopItem } from 'app/shared/models/shop/selected-shop-item.model';

export enum SelectedShopItemActionTypes {
  AddSelectedShopItem = '[Shop] Add selected item',
  RemoveSelectedShopItem = '[Shop] Remove selected item',
  RemoveAllSelectedShopItems = '[Shop] Remove all selected item',
}

export const addSelectedShopItem = createAction(
  SelectedShopItemActionTypes.AddSelectedShopItem,
  props<{ selectedShopItem: SelectedShopItem }>()
);

export const removeSelectedShopItem = createAction(
  SelectedShopItemActionTypes.RemoveSelectedShopItem,
  props<{ selectedItemId: string }>()
);

export const removeAllSelectedShopItems = createAction(
  SelectedShopItemActionTypes.RemoveAllSelectedShopItems
);
