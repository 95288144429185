import { createReducer, on } from '@ngrx/store';
import { setDarkMode } from '../actions/settings.actions';

export interface SettingsState {
  darkMode: boolean;
}

export const initialState: SettingsState = {
  darkMode: false,
};

export const settingsReducer = createReducer(
  initialState,
  on(setDarkMode, (state: SettingsState, { darkMode }) => ({
    ...state,
    darkMode: darkMode,
  }))
);
