import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CustomTranslatorModule } from 'app/shared/i18n/custom-translate.module';
import { ModalModule } from 'ngx-bootstrap/modal';

import { AsyncStateModule } from './async-state/async-state.module';
import { ShopModule } from './shop/shop.module';
import { CoreStoreModule } from './store/core-store.module';
import { ToastModule } from './toasts/toast.module';

@NgModule({
  imports: [
    CoreStoreModule,
    AsyncStateModule.forRoot(),
    ModalModule.forRoot(),
    ToastModule.forRoot(),
    CustomTranslatorModule,
    ShopModule,
  ],
  exports: [FormsModule, ReactiveFormsModule],
})
export class CoreModule {}
