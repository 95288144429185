import { createAction, props } from '@ngrx/store';

export enum DarkModeActionTypes {
  SetDarkMode = '[DarkMode] Is On',
}

export const setDarkMode = createAction(
  DarkModeActionTypes.SetDarkMode,
  props<{ darkMode: boolean }>()
);
